.events_resizable_headers {
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0px;
    max-width: 100%;
}

.events_resizable_headers thead th {
    resize: horizontal;
    /*overflow: auto;*/
    white-space: nowrap;
    overflow: hidden;
    max-width: max-content;
}

.responsive-header-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: normal;
    white-space: nowrap;
    max-width: 100%;
}
