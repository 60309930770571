body {
    margin: 0;
    padding: 0;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 1366px) {
    html {
        font-size: 11px !important;
    }

    body {
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 1600px) {
    html {
        font-size: 12px !important;
    }

    body {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 1920px) {
    html {
        font-size: 13px !important;
    }

    body {
        font-size: 13px !important;
    }
}

a {
    text-decoration: none;
    color: #000;
}
