.fixed_headers {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.fixed_headers thead th {
    resize: horizontal;
    overflow: auto;
    white-space: nowrap;
}

.fixed_headers td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}
