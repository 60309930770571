/*Tabs*/
.ui.secondary.pointing.menu .active.item {
    color: #1b3c71 !important;
    border-color: #1b3c71 !important;
}

/* Table */
.ui.table {
    margin: 0 !important;
}

.ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Input */
input:disabled {
    opacity: 1 !important;
}

/* Button */
.ui.primary.button {
    color: #fff !important;
    background: #1b3c71 !important;
}

/* Modal Background */
.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

/*iframe, #fc_frame, #fc_frame.fc-widget-normal {*/
/*  display: none;*/
/*}*/
