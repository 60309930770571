.orgView {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 0.75em 0 0.75em 0.5em;
    border-bottom: 1px solid #eee;
    /*background: rgb(255, 255, 255);*/
}

.labelColor-darkblue {
    --circleColor: #1b3c71;
}

.labelColor-green {
    --circleColor: #4aa96c;
}

.labelColor-yellow {
    --circleColor: #ffcc29;
}

.labelColor-orange {
    --circleColor: #ff8243;
}

.labelColor-grey {
    --circleColor: #777777;
}

.labelColor-red {
    --circleColor: #e34234;
}

.labelColor-black {
    --circleColor: #000;
}

.allVehicleLabelColor {
    background-color: var(--circleColor) !important;
}

.ui.basic.label {
    color: var(--circleColor) !important;
    border-color: var(--circleColor) !important;
}

.groupView {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 0.75em 0;
    /*border-bottom: 1px solid rgb(221, 221, 221);*/
    /*margin-left: -7%;*/
    background: #fff;
}

.labelBorder {
    border-color: black !important;
    border-style: dashed;
    border-width: thin;
}

.leafView {
    display: flex;
    align-items: stretch;
    position: relative;
    box-sizing: border-box;
    /*padding: 0.75em;*/
    margin: 0.5em 0.5em 0 0.5em;

    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

    cursor: pointer;

    transition: box-shadow 0.2s ease;
}

.orgView > .rct-text {
    padding: 3px 0;
}

.groupView .rct-text {
    box-sizing: border-box;
    /*padding: 0.75em 0em;*/
    /*margin: 0.75em;*/
    /*display: inline;*/
    /*padding: 1em;*/
}

.rct-title {
    align-items: center;
    /*padding: 0px 0px;*/
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.react-checkbox-tree ol {
    display: flex;
    flex-direction: column;
    /*padding-left: 2em;*/
}

.leafView .rct-title {
    display: block;
}

.leafView .rct-text {
    position: relative;
    padding: 0.75em 0;
    width: 100%;
    /*background-color: red;*/
}

/*img {*/
/*    height: 1.43em;*/
/*    float: right;*/
/*}*/

.leafView .rct-collapse {
    display: none;
}

/*span div {*/
/*  display: inline;*/
/*}*/
.react-checkbox-tree label {
    display: flex;
    margin-bottom: 0%;
    cursor: pointer;
    /*width: 100%;*/
    align-items: center;
}

.react-checkbox-tree ol ol ol {
    padding-left: 0;
    background: rgb(250, 250, 250);
}

.react-checkbox-tree ol ol {
    padding-left: 3px !important;
    /*background: rgb(250, 250, 250);*/
}

.react-checkbox-tree :hover {
    background: transparent !important;
}

.leafView :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.leafView > span :hover {
    box-shadow: none;
    /*background: transparent;*/
}

.rct-node-clickable {
    width: 100%;
}

.react-checkbox-tree {
    font-size: inherit !important;
}

.rct-node-clickable:hover {
    background: none !important;
}

.rct-node-clickable:focus {
    outline: 0;
    background: none !important;
}
